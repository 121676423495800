export default {
    header: {
        about: '关于我们',
        product: '产品',
		app:'APP',
        languages: '切换语言',
    },
    footer: {
        smallTitle: '联系我们',
        company: '广东麦珂威尔健康科技有限公司',
        address: '广东省东莞市松山湖怡然路2号万汇云谷C-1栋',
        other: [
            { name: '电话', value: '0769-22242418' },
            { name: '移动', value: '138 2729 3922' },
            { name: '邮箱', value: "jack{'@'}onemicowell.com" },
        ],
        qr: {
            wx: '公众号',
            service: '售后服务',
            app: 'App下载',
        },
        copyright: 'COPYRIGHT (©) 2022 广东麦珂威尔健康科技有限公司.　　技术支持'
    },
    index: {
        pageTop: {
            title: '无处不在的力量站',
            scrollLabel: '下滑',
            productLabel: '产品',
        },
        specification: {
            title: '产品规格',
            parameters: {
                dimension: {
                    unfolded: {
                        name: '展开尺寸',
                        value: '长863×宽83×高123(mm)',
                    },
                    folded: {
                        name: '折叠后尺寸',
                        value: '长429×宽83×高246(mm)',
                    },
                },
                floorArea: {
                    unfolded: {
                        name: '展开占地面积',
                        value: '0.065㎡',
                    },
                    folded: {
                        name: '折叠占地面积',
                        value: '0.032㎡',
                    }
                },
                netWeight: {
                    name: '净重',
                    value: '5.5kg',
                },
                ratingWeight: {
                    name: '电机额定输出力',
                    value: '10kgf',
                },
                maximumWeight: {
                    name: '电机最大输出力',
                    value: '15kgf',
                },
                betteryLife: {
                    name: '电池续航时间',
                    value: '120Minutes',
                },
                charging: {
                    name: '充电电压/电流',
                    value: '29.4V/1.5A',
                }
            },
        },
        xmaiCg: {
            title: 'CORE TECHNOLOGY',
            // title: '核心技术',
            functions: [
                {
                    title: '高性能电机',
                    text: '电机角度与温度实时采样，力矩可达20Kgf, 双边电机可达40Kgf',
                },
                {
                    title: '智能算法',
                    text: '提供5种不同的力矩模式，力可以精确到0.01Kgf',
                },
            ]
        },
        motorControl: {
            title: '双电机独立控制',
            label: '内置高性能无刷外转子电机，可折叠，便携式，通过蓝牙连接APP调节电磁阻力/专用耐磨高抗拉强度拉力绳',
        },
        adjustStrength: {
            title: '精准力量控制',
            label: '想你所想，做你所做，想怎么控制就怎么控制。',
        },
        controlModes: {
            title: '动态重量模式',
            label: '5种智能力量模式，在锻炼过程中动态调整重量，刺激肌肉，让锻炼更有效率',
            modes: {
                constant: {
                    name: '恒力模式',
                    label: '系统会提供恒定不变的用户所设定的力',
                },
                adaptive: {
                    name: '自适应模式',
                    label: '通过实时感知你的力与速度来自动增加或减少力，系统帮助你每次最大限度地调整到你能拉动的力',
                },
                increasing: {
                    name: '递增模式',
                    label: '在设定的基础力量上，每完成一次动作逐步增加力量,用户可以根据自身的情况设定不同的基础力',
                },
                spring: {
                    name: '弹簧模式',
                    label: '模仿现实拉弹簧力，通过位置判定， 设定好基础力，拉或推时线性递增，回绳时线性递减',
                },
                decreasing: {
                    name: '递减模式',
                    label: '在设定的基础力量上，每完成一次动作逐步减少特定比例的力量,用户可以根据自身的情况设定不同的基础力',
                },
            },
        },
        handle: {
            left: {
                title: '方便快捷的\n安装设计'
            },
            right: {
                number: '2000',
                titleWithoutNumber: '万次，最大',
                label: '拉绳长度',
            }
        },
        trainingPatterns: {
            title: '丰富的训练模式',
            content: '小麦智能力量训练器通过控制两侧电机的磁阻、速度和位置，并与不同部位配合，实现对身体的不同训练效果，可以取代传统的哑铃、杠铃，实现运动的数字化、智能化，与其他运动爱好者交流，可以丰富健身体验，让运动更有趣。',
        },
        linkDevice: {
            title: '小麦APP',
            label: '随时随地跟踪',
            text: 'MICOWELL移动应用程序是您的终极训练工具，使用它可以访问您的进度统计和运动记录, 设定运动计划和运动提醒, 收藏运动视频教程及分享运动风采等',
            downloadAndroid: '安卓',
            downloadApple: '苹果',
            downloadAndroidTitle: 'XMAI安卓版本',
            downloadAppleTitle: 'XMAI苹果版本',
            downloadLabel: '无处不在的力量站',
            downloadChinese: '中文版下载',
            downloadEnghlish: '英文版下载',
            download: '下载',
        },
        banner: [
            {
                // title: 'XMAI Fitness',
                // subtitle: 'MAKE SPORTS\nMORE FUN',
                // label: 'CABLE HOME GYM\nHIT THE POWER EVERYWHERE',
            },
            {
                // title: 'XMAI Fitness',
                // subtitle: 'THE POWER\nEVERYWHERE',
                // label: 'CABLE HOME GYM HIT',
            },
            {
                // title: '',
                // subtitle: 'MAKE SPORTS\nMORE FUN',
                // label: 'CABLE HOME GYM\nHIT THE POWER EVERYWHERE',
            }
        ],
        last: {
            title: '前500名买家可享受85%的折扣',
            afterNumber: '剩余\n位',
            button: '购买',
            label: '点击下方的“商品”进行定购',
        },
    },
  app: {
        topBanner: 'XMAI APP',
        info: {
            title: 'XMAI APP您的贴身运动管家',
            content: 'XMAI APP装有200多种标准动作，让您足不出户练全身，您可创建您专属的训练课程与运动计划，运动提醒及运动数据展现与汇总分析，让您的运动更高效更科学，是您的贴身免费运动健康管家' ,
			},
		getApp: {
		    title: '获取应用程序',
		    content: '根据不同的手机系统选择iOS或Android,扫描下图的二维码安装小麦APP,方便为您提供更加丰富的动作教学和更细致化的运动体验',
			},
			circle:{
				left:'自由模式',
				right:'沉浸式划船',
			},
		introduce:{
			title1:'创建属于您自己的\n运动计划与课程',
			title2:'运动数据汇总与分析',
			title3:'分享您的激情运动\n创建您的专属运动圈子',
		}
        
    },
   

    about: {
        topBanner: '助力全民健身 共建智慧运动',
        who: {
            title: 'MICOWELL',
            content: 'MICOWELL成立于2021年，是由一群年轻的电机及驱动研发技术团队组成， 致力于通过电机及电控技术提供力量型智能运动产品及其解决方案，MICOWELL通过研究电磁阻力控制技术与丰富的视频动作教程让你足不出户就可以锻炼全身，根据家庭成员的不同年龄段制定不同的科学运动模式与课程，不同的场景会让您爱上运动，享受运动带来的乐趣， 帮您打造家庭私人健身房，时刻守护你的健康\n\n作为力量型智能运动产品的开创者与引领者， MICOWELL利用其自身的电机控制技术与大数据管理优势，整合智能运动领域的优质资源， 为企业及社区打造智慧运动空间， 助力全民健身，共建智慧运动。',
        },
        spirit: {
            vision: {
                title: '愿景',
                content: '助力全民健身，共建智慧运动',
            },
            mission: {
                title: '使命',
                content: '提供更科学，更专业，更智慧的社区及居家健身解决方案',
            },
        },
        core: {
            title: '核心价值观',
            abstract: '健康， 诚信，包容，创新， 执行，担当， 互信， 共赢',
            content: '强调要以健康、诚信、包容为态度，创新、执行为行动，互信、卓越， 共赢为追求，脚踏实地把产品和服务做实做精，不断超越客户期望、引领客户需求，以产品和服务帮助客户提升价值创造力，实现与客户相互依存、共同成长。',
        },
        members: {
            title: '团队介绍',
            ceo: {
                name: '罗先树',
                role: '创始人',
                position: 'CEO',
                introduction: '毕于福州大学机电一体化工程专业， 深耕电机行业20多年，先后在万宝至，TDK， 德昌电机， TTI等 知名外企担任不同技术及管理岗位，2014年-2016负责美敦力在中国区心脏起搏器本土化项目首席运营官，已顺利通过CFDA临床 认证并在国内成功上市，2017年开始自主创业成立东莞张力测控技术有限公司， 专注于微特电机的综合性能测试，目前产品已广泛应用于军工航空航天，汽车及机器人领域的电机测试， 由于热爱健身运动，加上电机及电控测试的技术背景， 在 2021年4月份成立广东麦珂威尔健康科技有限公司，专注于通过电机研发力量型智能健身产品。',
            },
            cto: {
                name: '徐松',
                role: '联合创始人',
                position: 'CTO',
                abstract1: '电机与电器工学博士，毕业于中国科学院电工研究所电机与电器专业。',
                abstract2: '长期从事机器人驱动电机设计、新能源汽车驱动及特种电机关键技术研究与设计工作。',
                introduction: '主持或作为主要参与人完成国家自然科学基金、中国科学院A类先导专项课题、国家海洋可再生能源专项、贵州省科技支撑项目、浙江省创新基金等纵向科研项目，开发完成4MW分瓣式直驱风力发电机、机器人高转矩密度关节电机、高温高压强井下电机、汽车助力转向电机等产品。'
            },
            cmo: {
                name: '朱梓鸣',
                role: '联合创始人',
                position: 'CMO',
                abstract: '拥有15年以上品牌营销与设计经验',
                introduction: '创立国内第一机器人执行器品牌(INNFOS)并成功被收购,负责项目品牌运营与市场推广'
            },
            others: [
                {
                    name: '周武兵',
                    position: '首席运营官',
                },
                {
                    name: '梁伦飞',
                    position: '产品总监',
                },
                {
                    name: '陈云忠',
                    position: '高级嵌入式软件工程师',
                },
                {
                    name: '曹杰',
                    position: '高级嵌入开发工程师',
                },
                {
                    name: '尹芷轩',
                    position: 'APP 产品经理',
                },
            ]
        },
        partnerTitle: '合作伙伴',
    },
    product: {
        topBanner: {
            title1: 'MICOWELL',
            title2: '产品套餐',
        },
        package: {
            title: '哪种 MICOWELL 套餐适合您？',
            label: '选择您合适的套餐，把健身房带回家。',
        },
        accessoryBanner: {
            title: '配件',
            label: '在这里，您可以选择所需的其他配件',
        },
        uselessBtn: '查看详情',
    },
    qr: {
        label: '扫描二维码，关注XMAI微信公众号并进入商城购买',
        title: '小麦微信公众号',
        confirm: '确定',
    },
    productDetail: {
        nav_title: "商品详情",
        price_predict_label: "预计到手价",
        price_instuction: "实际到手价，与您选择的商品规格相关，具体以尾款页显示的价格为准",
        order_status_deposit_title: "支付定金",
        order_status_deposit_label_1: "待付款",
        order_status_deposit_label_2: "已付定金",
        order_status_balance_title: "补尾款",
        order_status_balance_label_1: "待付尾款",
        order_status_balance_label_2: "已付尾款",
        order_status_ship_title: "确认收货",
        order_status_ship_label_1: "已发货，待收货",
        order_status_ship_label_2: "已收货",
        activity_time_title: "活动时间",
        activity_time_deposit: "预付定金：",
        activity_time_balance: "补尾款：",
        good_params_title: "商品参数",
        good_params_section_title: "参数",
        good_params_section_size: "尺寸",
        good_params_section_weight: "重量",
        good_params_section_color: "颜色",
        list_total_price: "到手价",
        list_list: "清单",
        list_unlock_1: "解锁训练",
        list_unlock_num: "个",
        list_unlock_trailing: "（全部）",
        detail: "商品详情",
        bottom_labels_empty: "活动时间已过",
        bottom_labels_vieworder: "查看您的订单",
        bottom_labels_order: "马上预定XMAI",
        bottom_labels_deposit_paid: "定金已付，等待尾款阶段",
        bottom_labels_deposit_over: "预定时间已过",
        bottom_labels_balance_pre: "马上付款，先付先发货",
        bottom_labels_await_ship: "等待XMAI发货",
        bottom_labels_shipped: "XMAI已发货",
        bottom_labels_complete: "享受您的XMAI吧！",
        bottom_buttons_deposit: "支付定金",
        bottom_buttons_order: "查看订单",
        bottom_buttons_balance: "支付尾款",
        bottom_buttons_balance_paid: "已支付尾款",
        bottom_buttons_over: "活动结束",
        buy_button: "预定",
        xmai_name: "小麦健身舱",
        order_label: "现在订购，享受2023年免费安装配件活动赠送瑜伽垫",
        basic_accessories: "基础版配件：",
        folding_bench: "折叠卧推凳：",
        wall_mounting_fixture: "挂壁固定装置：",
        appearance: "外观：",
        appearance_label: "选择你喜欢的颜色。",
        color: "颜色-",
        order_now: "现在购买",
        tax_included: "含税",
        warranty: "1年保修",
        share_5: "5人共享",
        tab_details: "细节",
        tab_params: "规格",
    }
}