import { createApp } from 'vue'
import App from './App.vue'
import routes from './router/routes';
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import globalComponents from './constants/global_components';
import assetImage from './utils/asset_image';

// import VueLazyLoad from 'vue3-lazyload';
// import VueLazyload from 'vue-lazyload-next';
import VueLazyload from 'vue-lazyload';

import locale from './lang/locale';
import i18n from './lang/locale';

const app = createApp(App);

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (i18n.global.locale == "zh_CN") {
        to.meta.title && (document.title = to.meta.title);
    } else {
        to.meta.titleEn && (document.title = to.meta.titleEn)
    }
    next();
});

///导入全局组件
globalComponents.forEach((element) => {
    app.component(element.name, element.component);
})

app.config.globalProperties.$assetImage = assetImage;

app.use(router);
app.use(ElementPlus);

// app.use(locale.VueI18n);

app.config.globalProperties.$lang = locale;

// app.use(VueLazyLoad);
app.use(VueLazyload);
app.use(locale);
app.mount('#app');
