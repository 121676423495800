// export default {
//     getIcon(icon) {
//         return require("@/assets/icons/" + icon);
//     },

//     getImageIcon(icon) {
//         return require("@/assets/images/" + icon);
//     }
// }

export function getIcon(icon) {
    return require("@/assets/icons/" + icon);
}

export function getImageIcon(icon) {
    return require("@/assets/images/" + icon);
}