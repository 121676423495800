<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <router-view></router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import storageUtil from './utils/storage_util'

export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // }
  mounted: () => {
    storageUtil.setLocale(navigator.language.split('-')[0] == 'zh' ? 'zh_CN' : 'en_US');
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Barlow Semi Condensed-Regular", Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  font-size: 10px;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


//IOS
a:focus,
input:focus,
p:focus,
div:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-modify: read-write-plaintext-only;
}

:deep(img) {
  width: 100%;
  background-size: contain !important;
  background-position: center !important;
}

.global-hide-up {
  display: none;
}
</style>
