export default {
    header: {
        about: 'ABOUT',
        product: 'PRODUCT',
		app:'APP',
        languages: 'SWITCH LANGUAGES',
    },
    footer: {
        smallTitle: 'Contact us',
        company: 'Guangdong Micowell Health Technology Co.,Ltd.',
        address: 'Building C-1, Wanhui cloud Valley, No. 2, Yiran Road, SongshanhuPark, Dongguan City, Guangdong Province',
        other: [
            { name: 'Tel', value: '0769-22242418' },
            { name: 'Mob', value: '138 2729 3922' },
            { name: 'E-mail', value: "jack{'@'}onemicowell.com" },
        ],
        qr: {
            wx: 'Wechat Public Account',
            service: 'After-sales service',
            app: 'App download',
        },
        copyright: 'COPYRIGHT(©) 2022 Guangdong Micowell Health Technology Co.,Ltd.'
    },
    index: {
        pageTop: {
            title: 'THE POWER EVERYWHERE',
            scrollLabel: 'SCROLL',
            productLabel: 'PRODUCT',
        },
        specification: {
            title: 'PRODUCT SPECIFICATION',
            parameters: {
                dimension: {
                    unfolded: {
                        name: 'Dimension Unfolded',
                        value: 'L863×W83×H123(mm)',
                    },
                    folded: {
                        name: 'Dimension Folded',
                        value: 'L429×W83×H246(mm)',
                    },
                },
                floorArea: {
                    unfolded: {
                        name: 'Floor Area Unfolded',
                        value: '0.065㎡',
                    },
                    folded: {
                        name: 'Floor Area Folded',
                        value: '0.032㎡',
                    }
                },
                netWeight: {
                    name: 'Net Weight',
                    value: '5.5kg',
                },
                ratingWeight: {
                    name: 'Rated motor output weight',
                    value: '10kgf',
                },
                maximumWeight: {
                    name: 'Maximum motor output weight',
                    value: '15kgf',
                },
                betteryLife: {
                    name: 'Bettery Life',
                    value: '120Minutes',
                },
                charging: {
                    name: 'Charging Voltage (V) / Current (A)',
                    value: '29.4V/1.5A',
                }
            },
        },
        xmaiCg: {
            title: 'CORE TECHNOLOGY',
            functions: [
                {
                    title: 'HIGH-PROFMANCE MOTOR',
                    text: 'Real time sampling of motor angle and temperature, Each motor provides a maximum weight of 20Kgf and two motors provide 40Kgf',
                },
                {
                    title: 'INTELLIGENT ALGORITHM',
                    text: 'Provides 5 different weight modes, weight regulation can be accurate to 0.01Kgf',
                },
            ]
        },
        motorControl: {
            title: 'DUAL MOTOR\nINDEPENDENT CONTROL',
            label: 'Built-in powerful electric motor/ Versatile, Portable, Bluetooth Connected APP/ Powerful Resistance/ Exclusive long cable',
        },
        adjustStrength: {
            title: 'PRECISELY ADJUST\nSTRENGTH',
            label: 'Think what you think, do what you do, and control\nas much as you like.',
        },
        controlModes: {
            title: 'DYNAMIC WEIGHT MODES',
            label: '5 smart weight modes, dynamically adjust weight during your workout, stimulating your muscles and making your workout more efficient',
            modes: {
                constant: {
                    name: 'CONSTANT FORCE',
                    label: 'The system will provide a constant force set by the user',
                },
                adaptive: {
                    name: 'ADAPTIVE MODE',
                    label: 'Helps you get most out of every rep by adapting the resistance you feel down to milisecond, increasing or decreasing weight base on what it knows you can handle',
                },
                increasing: {
                    name: 'INCREASING MODE',
                    label: 'On the basis of the set basic force, the user can set different basic forces according to his own situation by gradually increasing the force after completing each action',
                },
                spring: {
                    name: 'SPRING MODE',
                    label: 'Simulates the feeling of pushing or pulling  on real spring, so every move gets more challenging as you lift up or push out',
                },
                decreasing: {
                    name: 'DECREASING MODE',
                    label: 'On the basis of the set basic force, the user can set different basic forces according to his own situation by gradually decreasing the force after completing each action',
                },
            },
        },
        handle: {
            left: {
                title: 'CONVENIENT\nAND FAST\nINSTALLATION\nDESIGN'
            },
            right: {
                number: '20',
                titleWithoutNumber: 'MILLIONS TIMES AT MAXIMUM',
                label: 'Wire length',
            }
        },
        trainingPatterns: {
            title: 'RICH IN TRANING PATTERNS',
            content: 'MICOWELL portable Fitness product  controls the magnetic resistance, speed, and position of the motors on both sides, and cooperates with different parts to achieve different training effects on the body which can replace the traditional dumbbells and barbells to realize the digitalization and intelligence of sports, and communicate with other sports enthusiasts can rich fitness experience to make sports more interesting.',
        },
        linkDevice: {
            title: 'MICOWELL APP',
            label: 'TRACK ANYTIME, ANYWHERE',
            text: 'The MICOWELL mobile app is your ultimate training tool, Use it to access your progress stats and streaks. set up the training plan reminding, collect sports video tutorials and share sports style, etc',
            downloadAndroid: 'Android',
            downloadApple: 'App store',
            downloadAndroidTitle: 'APP Android version',
            downloadAppleTitle: 'APP IOS version',
            downloadLabel: 'THE POWER EVERYWHERE',
            downloadChinese: 'Chinese version',
            downloadEnghlish: 'English version',
            download: 'Download',
        },
        banner: [
            {
                // title: 'XMAI Fitness',
                // subtitle: 'MAKE SPORTS\nMORE FUN',
                // label: 'CABLE HOME GYM\nHIT THE POWER EVERYWHERE',
            },
            {
                // title: 'XMAI Fitness',
                // subtitle: 'THE POWER\nEVERYWHERE',
                // label: 'CABLE HOME GYM HIT',
            },
            {
                // title: '',
                // subtitle: 'MAKE SPORTS\nMORE FUN',
                // label: 'CABLE HOME GYM\nHIT THE POWER EVERYWHERE',
            }
        ],
        last: {
            title: 'The Top 500 Buyers\nCan Enjoy A Discount Of 85%',
            afterNumber: 'Only\nPositions',
            button: 'PURCHASE',
            label: 'Click on "Commodities" below to purchase',
        },
    },
app: {
        topBanner: 'XMAI APP',
        info: {
            title: 'XMAI APP-YOUR PERSONAL SPORTS MANAGER',
            content: 'XMAI APP is being built with more than 200 standard movements,allows you to train your whole body without leaving home.It can create your own training courses and exercise plans,exercise reminders and exercise data display and summary analysis,.making your exercise more efficient and scientific.It is your Personal free sports and health manager',
        },
		getApp: {
		    title: 'GET THE COACHING APP',
		    content: 'Choose iOS or Android according to different mobile phone systems,scan the QR code in the picture below to install the wheat APP,which is convenient to provide you with richer movement teachingand more detailed exercise experience.',
		},
		circle:{
			left:'Free Mode',
			right:'Immersive Rowing',
		},
		introduce:{
			title1:'CREATE YOUR OWN EXERCISE PLANS AND WORKOUT SESSIONS',
			title2:'SPORTS DATA SUMMARY AND ANALYSIS',
			title3:'SHARE YOUR SPORTS EXCITEMENT AND CREATE YOUR OWN SPORTS CIRCLE OF',
		}
        
    },

    about: {
        topBanner: 'HELP NATIONAL FITNESS\nBUILD A SMART MOVEMENT',
        who: {
            title: 'WHO ARE MICOWELL',
            content: 'Founded in 2021, MICOWELL is composed of a group of young peoples WHO have been doing research in motor and drive  technology,  committed to providing power intelligent sports products and solutions through motor and electronic control technology. MICOWELL enables you to exercise your whole body without leaving home by through electromagnetic resistance control technology and rich video action tutorials, and formulates different scientific sports modes and courses according to the different age groups of family members, Different sports scenes will make you fall in love with sports, enjoy the fun of sports, help you build a family private gym, and protect your health at all times\n\nAs the pioneer and leader of power intelligent sports products, MICOWELL uses its own motor control technology and big data management advantages and integrate high-quality resources in the field of intelligent sports, and our vision is to create a smart sports space for enterprises and communities, help people with fitness, and jointly build smart sports.',
        },
        spirit: {
            vision: {
                title: 'VISION',
                content: 'Support the national fitness in jointly building up the intelligence sports platform',
            },
            mission: {
                title: 'MISSION',
                content: 'Provide more scientific, professional and intelligent community and home fitness solutions',
            },
        },
        core: {
            title: 'CORE VALUE',
            abstract: 'Health, integrity, inclusiveness, innovation, implementation, responsibility, mutual trust and win-win results',
            content: 'It is emphasized that we should take health, integrity and inclusiveness as the attitude, innovation and implementation as the action, mutual trust, excellence and win-win as the pursuit, make products and services practical and refined, constantly exceed customer expectations, lead customer needs, help customers improve value creativity with products and services, and realize interdependence and common growth with customers.'
        },
        members: {
            title: 'CORE TEAM MEMBERS',
            ceo: {
                name: 'LUO XIANSHU',
                role: 'FOUNDER',
                position: 'CEO',
                introduction: "Graduated from Fuzhou University, majored in Mechatronics Engineering, and has been deeply involved in the motor industry for more than 20 years. He has successively held different technical and management positions in well-known foreign enterprises such as MBUCHI, TDK, Dechang electric, TTI, etc. from 2014 to 2016, he was responsible for the chief operating officer of Medtronic's localization project of cardiac pacemakers in China, it has successfully passed the CFDA clinical certification and listed in China market. In 2017, he started his own business to establish Tension Technology (DONGGUAN)LIMITED, Focusing on the comprehensive performance testing of micro motors, the products have been widely used in the field of military aerospace, automotive and robot motor testing. Due to my love for fitness sports, coupled with the technical background of motor and electronic control testing, Guangdong MICOWELL Health Technology Co., Ltd. was established in April 2021, focusing on the research and development of strength intelligent fitness products through motors."
            },
            cto: {
                name: 'XU SONG',
                role: 'CO FOUNDER',
                position: 'CTO',
                abstract1: 'Doctor of electrical engineering and electrical engineering, graduated from the Institute of electrical engineering, Chinese Academy of Sciences, majoring in electrical engineering and electrical appliances',
                abstract2: 'He has been engaged in the design of robot drive motor, the research and design of key technologies of new energy vehicle drive and special motor for a long time',
                introduction: 'Preside over or as a major participant to complete vertical scientific research projects such as the National Natural Science Foundation of China, the class a pilot special project of the Chinese Academy of Sciences, the National Marine renewable energy project, the Guizhou Provincial Science and technology support project, and the Zhejiang provincial innovation fund, and develop and complete 4MW split direct drive wind turbine generator, robot high torque density joint motor, high temperature and high pressure strong underground motor, automobile power steering motor and other products'
            },
            cmo: {
                name: 'KEVIN ZHU',
                role: 'CO FOUNDER',
                position: 'CMO',
                abstract: 'With more than 15 years of experience in brand marketing and design',
                introduction: 'he founded the first domestic robot actuator brand (INNFOS)and was successfully acquired, responsible for project brand operation and marketing'
            },
            others: [
                {
                    name: 'WUBIN ZHOU',
                    position: 'CHIEF OPERATION OFFICER',
                },
                {
                    name: 'LUNFEI LIANG',
                    position: 'PRODUCT DIRECTOR',
                },
                {
                    name: 'YUNZHONG CHEN',
                    position: 'SR. EMBEDDED SOFTWARE DEVELOPMENT ENGINEER',
                },
                {
                    name: 'CAOJIE',
                    position: 'SENIOR EMBEDDED DEVELOPMENT ENGINEER',
                },
                {
                    name: 'ZHIXUAN YIN',
                    position: 'APP PRODUCT MANAGER',
                },
            ]
        },
        partnerTitle: 'MEET OUR PARTNERS',
    },
    product: {
        topBanner: {
            title1: 'MICOWELL',
            title2: 'PRODUCT PACKAGE',
        },
        package: {
            title: 'Which MICOWELL Package is right for you?',
            label: 'Pick the perfect package to transform your home into a complete home gym.',
        },
        accessoryBanner: {
            title: 'ACCESSORIES',
            label: 'Here you can choose the additional accessories you need',
        },
        uselessBtn: 'VIEW DETAILS',
    },
    qr: {
        label: 'Scan the QR-code, follow the XMAI Wechat public account and enter the official mall to purchase',
        title: 'XMAI Wechat Public Account',
        confirm: 'Confirm',
    },
    productDetail: {
        nav_title: "Product Details",
        price_predict_label: "Estimated Price",
        price_instuction: "The actual price, with your choice of product specifications, the specific price shown on the final page shall prevail",
        order_status_deposit_title: "Pay deposit",
        order_status_deposit_label_1: "Pending payment",
        order_status_deposit_label_2: "Deposit Paid",
        order_status_balance_title: "Final payment",
        order_status_balance_label_1: "Pending final payment",
        order_status_balance_label_2: "Final Payment Paid",
        order_status_ship_title: "Confirm receipt",
        order_status_ship_label_1: "Shipped, pending receipt",
        order_status_ship_label_2: "Received",
        activity_time_title: "Event Time",
        activity_time_deposit: "Deposit: ",
        activity_time_balance: "Final payment: ",
        good_params_title: "Product parameters",
        good_params_section_title: "Parameters",
        good_params_section_size: "Size",
        good_params_section_weight: "Weight",
        good_params_section_color: "Color",
        list_total_price: "Price ",
        list_list: "List",
        list_unlock_1: "Unlock Trainings ",
        list_unlock_num: "",
        list_unlock_trailing: " (all)",
        detail: "Product Details",
        bottom_labels_empty: "Event time has expired",
        bottom_labels_vieworder: "View your order",
        bottom_labels_order: "Order XMAI now",
        bottom_labels_deposit_paid: "Waiting for final payment stage",
        bottom_labels_deposit_over: "Scheduled time has expired",
        bottom_labels_balance_pre: "Pay Now",
        bottom_labels_await_ship: "Waiting for XMAI to ship",
        bottom_labels_shipped: "XMAI has shipped",
        bottom_labels_complete: "Enjoy your XMAI!",
        bottom_buttons_deposit: "Pay deposit",
        bottom_buttons_order: "View Order",
        bottom_buttons_balance: "Pay final payment",
        bottom_buttons_balance_paid: "Final payment paid",
        bottom_buttons_over: "The event is over",
        buy_button: "Order",
        xmai_name: "XMAI Fitness Cabin",
        order_label: "Order now and enjoy 2023 free installation accessories campaign free yoga mat.",
        basic_accessories: "Basic accessories:",
        folding_bench: "Folding bench:",
        wall_mounting_fixture: "Wall mounting fixture:",
        appearance: "Appearance:",
        appearance_label: "Choose your favorite color.",
        color: "Color - ",
        order_now: "Order now",
        tax_included: "Tax included",
        warranty: "1-year warranty",
        share_5: "5 people sharing",
        tab_details: "Details",
        tab_params: "Specification",
    }
}