// export default ({ path = '@/assets/images', filename }) => {
//     let pixelRatio = window.devicePixelRatio;
//     console.log(pixelRatio);
//     if (pixelRatio >= 3) {
//         // path = `${path}/@2x/${filename}`;
//         filename = `/@2x/${filename}`;
//     } else {
//         // path = `${path}/${filename}`;
//         filename = `/${filename}`;
//     }
//     // require("@/assets/images")
//     // return require(path);
//     return require("@/assets/images" + filename);
// }

// export default (filename) => {
//     let pixelRatio = window.devicePixelRatio;
//     // console.log(pixelRatio);
//         filename = `/${filename}`;
//     if (pixelRatio >= 3) {
//         // path = `${path}/@2x/${filename}`;
//         filename = `/@2x/${filename}`;
//     } else {
//         // path = `${path}/${filename}`;
//         filename = `/${filename}`;
//     }
//     // require("@/assets/images")
//     // return require(path);
//     return require("@/assets/images" + filename);
// }

export default (filename) => {
    filename = `/${filename}`;
    return require("@/assets/images" + filename);
}