<template>
    <div class="footer">
        <div class="content">
            <div class="upper">
                <div class="left">
                    <div class="small-title">{{ localT('smallTitle') }}</div>
                    <div class="company-name">{{ localT('company') }}</div>
                    <div class="address">
                        <div class="icon-holder">
                            <img :src="iconsMap.iconAddress" alt="address" class="icon address">
                        </div>
                        <span class="address-span" :class="{ zhAddressSpan: isChinese }">{{ localT('address') }}</span>
                    </div>
                    <div class="other">
                        <div class="icon-holder">
                            <img :src="iconsMap.iconTel" alt="tel" class="icon tel">
                        </div>
                        <div class="title">{{ localT('other[0].name') }}:</div>
                        <div class="tel-number value">{{ localT('other[0].value') }}</div>
                        <div class="icon-holder">
                            <img :src="iconsMap.iconMob" alt="mob" class="icon mob">
                        </div>
                        <div class="title">{{ localT('other[1].name') }}:</div>
                        <div class="mob-number value">{{ localT('other[1].value') }}</div>
                        <div class="icon-holder">
                            <img :src="iconsMap.iconMail" alt="mail" class="icon mail">
                        </div>
                        <div class="title">{{ localT('other[2].name') }}:</div>
                        <div class="email-address value">{{ localT('other[2].value') }}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="wechat href-item">
                        <div class="qr-code-holder">
                            <img v-lazy="qrCodes.wx" alt="qr_code" class="qr-code">
                            <!-- <img src="https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-07-29/165908869596020.jpg"
                                alt="qr_code" class="qr-code"> -->
                        </div>
                        <div class="label">{{ localT('qr.wx') }}</div>
                    </div>
                    <div class="service href-item">
                        <div class="qr-code-holder">
                            <img v-lazy="qrCodes.service" alt="qr_code" class="qr-code">
                            <!-- <img src="https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-07-29/165908869596020.jpg"
                                alt="qr_code" class="qr-code"> -->
                        </div>
                        <div class="label">{{ localT('qr.service') }}</div>
                    </div>
                    <div class="app href-item">
                        <div class="qr-code-holder">
                            <img v-lazy="qrCodes.app" alt="qr_code" class="qr-code">
                            <!-- <img src="https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-07-29/165908869596020.jpg"
                                alt="qr_code" class="qr-code"> -->
                        </div>
                        <div class="label">{{ localT('qr.app') }}</div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="lower">
                <div class="logo-holder">
                    <img :src="iconsMap.logoSmall" alt="logo" class="logo">
                </div>
                <div class="copyright">
                    {{ localT('copyright') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { iconsMap } from "@/constants/constants";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import i18n from "@/lang/locale.js";
// import ua from "@/utils/user_agent";

const localT = (path) => {
    return i18n.global.t(`footer.${path}`);
}

const isChinese = computed(() => {
    return i18n.global.locale == "zh_CN";
})

const { proxy } = getCurrentInstance();
const assetImage = proxy.$assetImage;

const qrCodes = {
    wx:
        "https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-09-07/1662514662285.jpg",
    service:
        // "https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-09-07/1662514678697.png",
        "https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-10-27/1666834577100.jpg",
    app:
        "https://micowell.oss-cn-shenzhen.aliyuncs.com/2022-09-07/1662514714549.png",
};


</script>
<style scoped lang='scss'>
.footer {
    padding: 4.5833vw 0 1.0938vw 0;
    background-color: $color-0f0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content {
        // margin-top: 4.2188vw;
        width: 65.63vw;

        .upper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                text-align: start;
                width: 40%;

                .icon-holder {
                    width: 1.25vw;
                    height: 1.25vw;
                    flex-shrink: 0;

                    .icon {
                        width: 100%;
                        object-fit: contain;
                        margin: 0;
                        display: block
                    }
                }

                .small-title {
                    @include font(0.8333vw, $color-white, 1, $font-huawei-regular);
                }

                .company-name {
                    margin-top: 0.5208vw;
                    @include font(0.9375vw, $color-white, 1, $font-huawei-bold);
                }

                .address {
                    align-items: center;
                    display: flex;
                    margin-top: 1.0417vw;
                    @include font(0.7292vw, $color-white, 1.0938vw, $font-huawei-light);

                    .address-span {
                        margin-left: 1.0417vw;
                    }
                }

                .other {
                    margin-top: 0.94vw;
                    display: grid;
                    grid-template-columns: 2.2917vw 3.3333vw 10.4167vw;
                    row-gap: 1.0417vw;
                    align-items: center;

                    .other-line {
                        &:not(:first-child) {
                            margin-top: 1.04vw;
                        }

                        // display: flex;
                    }

                    .title {
                        @include font(0.9375vw, $color-f2f, 1, $font-huawei-regular);
                    }

                    .value {
                        @include font(0.9375vw, $color-e5e, 1, $font-huawei-regular);
                    }

                    .other-item {

                        &:not(:last-child) {
                            margin-right: 3.13vw;
                        }
                    }
                }
            }

            .right {
                display: flex;

                .href-item {
                    width: 6.25vw;

                    .qr-code {
                        width: 100%;
                        object-fit: contain;
                    }

                    .label {
                        margin-top: 1.0417vw;
                        @include font(0.8333vw, $color-white, 1, $font-huawei-light);
                    }

                    &:not(:last-child) {
                        margin-right: 2.6042vw;
                    }
                }
            }
        }

        .lower {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo-holder {
                width: 16.88vw;

                .logo {
                    width: 100%;
                    object-fit: contain;
                    margin-left: -8%;
                }
            }

            .copyright {
                @include font(0.73vw, $color-white, 1, $font-huawei-light);
                // white-space: pre-wrap;
            }
        }

        .divider {
            margin: 2.5vw 0 1.4063vw 0;
        }
    }
}


@media only screen and (max-width: 500px) {
    .footer {
        padding-bottom: 4.5833vw;

        .content {
            width: 90vw;

            .upper {
                flex-direction: column;

                .left {
                    width: inherit;

                    .icon-holder {
                        width: 2.75vw;
                        height: 2.75vw;
                    }

                    .small-title {
                        @include font(2vw, $color-white, 1, $font-huawei-regular);
                    }

                    .company-name {
                        margin-top: 1vw;
                        @include font(2vw, $color-white, 1, $font-huawei-bold);
                    }

                    .address {
                        margin-top: 1vw;
                        @include font(2vw, $color-white, 1, $font-huawei-light);

                        .address-span {

                            margin-left: 2.5417vw;
                        }

                        .zhAddressSpan {
                            font-size: 0.9375vw;
                        }
                    }

                    .other {
                        margin-top: 2.94vw;
                        display: grid;
                        grid-template-columns: 5.2917vw 11.3333vw 48.4167vw;
                        row-gap: 1.0417vw;

                        .other-line {
                            &:not(:first-child) {
                                margin-top: 1.04vw;
                            }

                            // display: flex;
                        }

                        .title {
                            @include font(2vw, $color-f2f, 1, $font-huawei-regular);
                        }

                        .value {
                            @include font(2vw, $color-e5e, 1, $font-huawei-regular);
                        }

                        .other-item {

                            &:not(:last-child) {
                                margin-right: 3.13vw;
                            }
                        }
                    }
                }

                .right {
                    margin-top: 3vw;
                    // justify-content: center;
                    justify-content: space-evenly;

                    .href-item {
                        width: 15.85vw;

                        .qr-code {
                            width: 100%;
                            object-fit: contain;
                        }

                        .label {
                            margin-top: 0.73vw;
                            @include font(2vw, $color-white, 1, $font-huawei-light);
                        }

                        &:not(:last-child) {
                            margin-right: 4.25vw;
                        }
                    }
                }
            }

            .lower {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo-holder {
                    width: 20vw;

                    .logo {
                        width: 100%;
                        object-fit: contain;
                        margin-left: -5%;
                    }
                }

                .copyright {
                    @include font(2vw, $color-white, 1, $font-huawei-light);
                }
            }

            .divider {
                margin: 4vw 0;
            }
        }
    }
}
</style>