<template>
    <div class="header">
        <div class="gradient-bar"></div>
        <div class="header-inner">
            <!-- <div class="logo"></div> -->
            <router-link :to="{ path: '/' }" style="outline: none">
                <img class="logo" style="outline: none" :src="iconsMap.logo" alt="micowell">
            </router-link>
            <ul class="navigations" style="user-select: none">
                <li class="navi-item" style="user-select: none" v-for="(item, index) in navigations"
                    :key="`navigation${index}`">
                    <router-link class="navi-link" style="user-select: none" :to="{ path: item.path }">
                        <div class="navi-title" style="user-select: none">
                            <div style="user-select: none">{{ item.title }}</div>
                        </div>
                        <img class="navi-icon" style="user-select: none" v-if="item.icon != undefined" :src="item.icon"
                            alt="product">
                    </router-link>
                </li>
            </ul>
            <div class="lang" @click="switchLanguage">
                <img :src="locale == 'zh_CN' ? zhIcon : enIcon" alt="language" class="lang-icon">
                <!-- {{ locale == "zh_CN" ? "中文" : "EN" }} -->
            </div>
            <div class="nav-btn" ref="navBtn" @click="toggleList()">
                <img class="nav-btn-svg" :src="iconsMap.menu" alt="menu">
            </div>
        </div>
        <transition name="navList">
            <div class="nav-list-mobile" v-show="showNavList">
                <ul class="nav-list">
                    <li class="navi-item" style="user-select: none" v-for="(item, index) in navigations"
                        :key="`navigation${index}`">
                        <router-link class="navi-link" style="user-select: none" :to="{ path: item.path }">
                            <span class="navi-title" style="user-select: none">{{ item.title }}</span>
                            <img class="navi-icon" v-if="item.icon != undefined" :src="item.icon" alt="product">
                        </router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>
<script>
import { iconsMap } from '@/constants/constants';
import { reactive, ref } from '@vue/reactivity';
import { computed, getCurrentInstance, onMounted } from '@vue/runtime-core';
import i18n from '@/lang/locale';
import storageUtil from '@/utils/storage_util';
import zhIcon from '@/assets/icons/lang_zh.svg';
import enIcon from '@/assets/icons/lang_en.svg';

export default {
    name: "Header",
    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        const showNavList = ref(false);
        let btnBlured = false;
        let listBlured = false;

        const $t = i18n.global.t;

        const navigations = computed(() => {
            return [
                {
                    title: $t('header.about'),
                    path: "/about",
                },
				{
				    title: $t('header.app'),
				    path: "/app",
				},
                {
                    title: $t('header.product'),
                    icon: iconsMap.product,
                    path: "/products",
                }
            ];
        })

        const locale = computed(() => {
            return i18n.global.locale;
        })

        onMounted(() => {
            // console.log(proxy.$refs.navBtn)
            // proxy.$refs.navBtn.onclick(() => {
            // })
        })

        const toggleList = () => {
            showNavList.value = !showNavList.value;
        }

        const switchLanguage = () => {
            let localeArr = i18n.global.availableLocales;
            const getNewIndex = (oldIndex) => {
                let newIndex = oldIndex + 1;
                if (newIndex == localeArr.length) {
                    newIndex = 0;
                }
                return newIndex;
            }
            let index = localeArr.findIndex((value) => {
                return i18n.global.locale == value;
            });
            const newLocale = localeArr[getNewIndex(index)];
            storageUtil.setLocale(newLocale);
            i18n.global.locale = newLocale;
        }

        return {
            showNavList,
            toggleList,
            iconsMap,
            navigations,
            switchLanguage,
            $t,
            locale,
            zhIcon,
            enIcon,
        }

    }
}
</script>
<style scoped lang="scss">
.header {
    @include font-header;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1600);
    background-color: $background-black;
    z-index: 2;

    .nav-list-mobile {
        display: none;
        position: absolute;
        top: 17vw;
        height: 22vw;
        width: 100%;
        @include font-header-mobile;

        .nav-list {
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            background-color: $color-black;
            justify-content: space-evenly;
            padding: 0 6vw;
            height: 22vw;

            .navi-item {
                outline: none;
                //margin: 3vw 0;
            }

            .navi-link {
                outline: none;
                height: 100%;
                text-decoration: none;
                transition: all 0.1s;

                display: flex;
                align-items: center;

                &:visited,
                &:link {
                    color: $color-white;
                    outline: none;
                }

                &:active,
                &:hover {
                    outline: none;
                    opacity: 0.8;
                }

                .navi-icon {
                    outline: none;
                    margin-left: 2vw;
                    width: 5vw;
                    height: 5vw
                }
            }
        }
    }

    .gradient-bar {
        width: 100%;
        height: 8px;
        background-image: linear-gradient(90deg, #FF0000, #DACB12, #267CB2, #55BDFF, #0404AF, #AD36EB);
        flex-shrink: 0;
    }

    .header-inner {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 100px;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        z-index: 1;
        background-color: black;

        .logo {
            width: 290px;
            //height: 44px;
            margin-top: 6px;
            margin-left: -10%;

            transition: all 0.1s;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .lang {
        margin-left: 2vw;
        height: 46%;
        box-sizing: border-box;
        cursor: pointer;
        // border: $color-white 2px solid;
        // border-radius: 0.3125vw;
        // @include font()
        transition: all 0.2s;

        .lang-icon {
            height: 100%;
            object-fit: contain;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .nav-btn {
        display: none;
        margin-left: 5vw;

        transition: all 0.1s;

        &:hover {
            opacity: 0.8;
        }

        .nav-btn-svg {
            height: 6vw;
            width: 6vw;
        }
    }

    .navigations {
        outline: none;
        margin-left: auto;
        list-style-type: none;
        display: flex;
        align-items: center;

        .navi-item {
            outline: none;
            user-select: none;

            &:not(:last-child) {
                margin-right: 2vw;
            }
        }

        .navi-link {
            outline: none;
            text-decoration: none;
            transition: all 0.1s;
            user-select: none;

            display: flex;
            align-items: center;

            &:visited,
            &:link {
                .navi-title {
                    user-select: none;
                }

                user-select: none;
                color: $color-white;
            }

            &:active,
            &:hover {
                .navi-title {
                    user-select: none;
                }

                user-select: none;
                opacity: 0.8;
            }

            .navi-icon {
                margin-left: 12px;
            }

            .navi-title {
                user-select: none;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        height: 18vw;

        .nav-list-mobile {
            display: block;
        }

        .header-inner {
            padding: 0 4vw;

            .logo {
                width: 50vw;
            }
        }

        .lang {
            margin-left: auto;
        }

        .nav-btn {
            display: block;
        }

        .navigations {
            display: none;
        }

        .gradient-bar {
            height: 1vw;
        }
    }

    .navList-enter-from,
    .navList-leave-to {
        height: 0vw;
    }

    .navList-enter-to,
    .navList-leave-to {
        transition: all 0.1s
    }
}
</style>