// import Index from "@/pages/index/Main.vue";
// import About from "@/pages/about/Main.vue";
// import Product from "@/pages/product/Main.vue";

const Index = () => import("@/pages/index/Main.vue");
const About = () => import("@/pages/about/Main.vue");
const Product = () => import("@/pages/product/Main.vue");
const App = () => import("@/pages/app/Main.vue");
const ProductDetail = () => import("@/pages/product_detail/Main.vue");

const routes = [
    {
        path: "/",
        component: Index,
        meta: {
            titleEn: 'MICOWELL',
            title: 'MICOWELL',
        }
    },
    {
        path: "/about",
        component: About,
        meta: {
            titleEn: 'About | MICOWELL Official Website',
            title: '关于 | MICOWELL 官网',
        }
    },
	{
	    path: "/app",
	    component: App,
	    meta: {
	        titleEn: 'APP | MICOWELL Official Website',
	        title: 'APP | MICOWELL 官网',
	    }
	},
    {
        path: "/products",
        component: Product,
        meta: {
            titleEn: 'Products | MICOWELL Official Website',
            title: '产品 | MICOWELL 官网',
        }
    },
    {
        path: "/product_detail",
        component: ProductDetail,
        meta: {
            titleEn: 'Packages | MICOWELL Official Website',
            title: '产品 | MICOWELL 官网',
        }
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/'
    },
    // {
    //     path: "/"
    // }
]

// const router = VueRouter.createRouter({
//     history: VueRouter.createWebHistory(),
//     routes,
// });

export default routes;
