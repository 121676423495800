const storageItem = {
    locale: "LOCALE"
}

export default {
    getLocale: () => {
        return localStorage.getItem(storageItem.locale);
    },
    setLocale: (locale) => {
        localStorage.setItem(storageItem.locale, locale);
    }
}