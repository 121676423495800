// class Constants {
//     ///true 国内，false 海外
//     static isDomesticVersion = true;

//     static iconsMap = {
//         product: require("@/assets/icons/product.svg")
//     }
// }
import { getIcon, getImageIcon } from "@/utils/get_icon"

export default {
    ///true 国内，false 海外
    isDomesticVersion: true,
}

export const iconsMap = {
    product: getIcon("product.svg"),
    logoSmall: getImageIcon("logo_small.svg"),
    // logo: getImageIcon("logo.svg"),
    logo: getImageIcon("logo_small.svg"),
    // logoNew: getImageIcon("")
    menu: getIcon("menu.svg"),
    backgroundLogo: getImageIcon("background_logo.svg"),
    play: getIcon("play.svg"),
    arrowLeft: getIcon("arrow_left.svg"),
    arrowRight: getIcon("arrow_right.svg"),
    indexFunction1: getIcon("function_1.svg"),
    indexFunction2: getIcon("function_2.svg"),
    indexFunction3: getIcon("function_3.svg"),
    iconAddress: getIcon("icon_address.svg"),
    iconMail: getIcon("icon_mail.svg"),
    iconMob: getIcon("icon_mob.svg"),
    iconTel: getIcon("icon_tel.svg"),
}