import storageUtil from "@/utils/storage_util";
import { createI18n } from "vue-i18n";
import zh from "./zh_CN";
import en from "./en_US";

const storageLocale = storageUtil.getLocale();

const tempLocale = navigator.language.split("-")[0] == "zh" ? "zh_CN" : "en_US";

const locale = storageLocale ? storageLocale : tempLocale;

const config = {
    locale,
    messages: {
        'zh_CN': zh,
        'en_US': en,
    },
    fallbackLocale: 'en_US',
}

// const initLocale = () => createI18n(config);
const i18n = createI18n(config);

export default i18n;